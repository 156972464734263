<template>
    <span>
        <div
                v-for="data in styledBoundings"
                class="popup"
                :style="data.style"
                :class="data.classes"
                :key="data.key"
        >
            {{data.el.text}}
        </div>
    </span>
</template>

<script>
    import {mapGetters} from 'vuex'

    export default {
        name: "SequenceItemPopup",
        computed: {
            ...mapGetters(['sequences_store/getActiveSequence', 'getCurrentWrappedElementsBoundings']),
            styledBoundings() {
                return this.getCurrentWrappedElementsBoundings.map((el, i) => {
                    let style = {}
                    let classes = []

                    if (el.left) {
                        style.right = document.getElementsByTagName('body')[0].getBoundingClientRect().width - el.bounding.right + 'px'
                        classes.push('popup--left')
                    } else {
                        style.left = el.bounding.left + 'px'
                        classes.push('popup--right')
                    }

                    if (el.top) {
                        style.bottom = document.getElementsByTagName('body')[0].getBoundingClientRect().height - el.bounding.top + 'px'
                        classes.push('popup--top')
                    } else {
                        style.top = el.bounding.bottom + 'px'
                        classes.push('popup--bottom')
                    }

                    if (el.width)
                        style.maxWidth = el.width + 'px'

                    return {
                        el,
                        style,
                        classes,
                        key: i + (el.id ? el.id : el.text)
                    }
                })
            }
        }
    }
</script>

<style lang="scss" scoped>
    .popup {
        pointer-events: none;
        margin-top: 10px;
        margin-bottom: 10px;
        padding: 15px;
        position: absolute;
        background-color: white;
        width: fit-content;
        max-width: 300px;
        z-index: 100000;
        border: 1.5px orange solid;

        &:before {
            content: "";
            position: absolute;
            width: 0;
            height: 0;
            border-style: solid;
        }

        &--bottom:before {
            bottom: 100%;
            border-width: 4px 12px 16px 12px;
            border-color: transparent transparent orange transparent;
        }

        &--top:before {
            top: 100%;
            border-width: 16px 12px 4px 12px;
            border-color: orange transparent transparent transparent;
        }

        &--left:before {
            right: 0;
        }

        &--right:before{
            left: 0
        }
    }
</style>